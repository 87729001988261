import { Stack, Typography, Box, Link } from '@mui/material';
import React from 'react';
import useLocales from '#/hooks/useLocales';
import Image from '#/components/shared/ui/Image';

export function SpecialBanner() {
  return (
    <Stack
      sx={{
        backgroundColor: '#091A7A',
        paddingX: 2,
        paddingY: 1,
        borderRadius: 5,
        display: 'inline-block',
        width: 'fit-content',
      }}
    >
      <Typography color="white">GrosseItern Magazin Special</Typography>
    </Stack>
  );
}

export function Save50() {
  const { translate } = useLocales();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        variant="h5"
        color="primary"
        sx={{
          textDecoration: 'underline',
        }}
      >
        {String(translate('grosseItern.mainBanner.sale.blueText'))}
      </Typography>
      <Typography variant="body2">
        {String(translate('grosseItern.mainBanner.sale.blackText'))}
      </Typography>
    </Stack>
  );
}

export function Angebot() {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
      }}
    >
      <Stack
        spacing={2}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6">
          {highlightWords(String(translate('grosseItern.angebot.phrase')), [
            String(translate('grosseItern.angebot.highlightedPhrase')),
          ])}
        </Typography>
        <Image src="/assets/images/angebot.webp" alt="Grandparents Magazine" />
      </Stack>
    </Box>
  );
}

function highlightWords(
  sentence: string,
  targetPhrases: string[],
  tooltip?: string
) {
  let elements: React.ReactNode[] = [sentence];
  targetPhrases.forEach((phrase, i) => {
    elements = elements.flatMap((el, j) => {
      if (typeof el === 'string') {
        const parts = el.split(new RegExp(`(${phrase})`, 'gi'));
        return parts.map((part, k) =>
          part.toLowerCase() === phrase.toLowerCase() ? (
            <Link
              href="https://grosseltern-magazin.ch/"
              // target="_blank"
              key={`${i}-${j}-${k}`}
              sx={{ color: '#3366FF', cursor: 'pointer' }}
            >
              {part}
            </Link>
          ) : (
            part
          )
        );
      }
      return el;
    });
  });
  return elements;
}
