import { Box, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import useLocales from '#/hooks/useLocales';
import Image from '#/components/shared/ui/Image';

export default function TrustLaurel() {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginY: 2,
      }}
    >
      <Image
        src={'/assets/icons/laurel_left.svg'}
        alt="trust-laurel"
        sx={{
          width: '24px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={1}>
          {Array.from({ length: 5 }).map((_, index) => (
            <Icon
              key={index}
              icon={
                index === 4 ? 'ic:baseline-star-half' : 'ic:baseline-star-rate'
              }
              color="#FCB41A"
              width={28}
            />
          ))}
        </Stack>
        <Typography variant="body2">
          {String(translate('global.customerOpinion'))}
        </Typography>
      </Box>
      <Image
        src={'/assets/icons/laurel_right.svg'}
        alt="trust-laurel"
        sx={{
          width: '24px',
        }}
      />
    </Box>
  );
}
